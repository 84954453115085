import request from '@/utils/request'

export function getOperator(parameter) {
  return request({
    url: '/user/',
    method: 'get',
    params: parameter
  })
}

export function queryOperators(parameter) {
  return request({
    url: '/user',
    method: 'post',
    data: parameter
  })
}

export function putOperator(parameter) {
  return request({
    url: '/user',
    method: 'put',
    data: parameter
  })
}

export function removeOperator(parameter) {
  return request({
    url: '/user/delete/' + parameter.id,
    method: 'delete'
  })
}

