<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-card :body-style="{padding: '24px 32px'}" :bordered='false'>
      <a-form-model ref='form' :model='form' :rules='rules' :labelCol='labelCol' :wrapperCol='wrapperCol'>
        <a-form-model-item label='用户名称' prop='nickname'>
          <a-input v-model='form.nickname' />
        </a-form-model-item>
        <a-form-model-item label='登陆账户' prop='username'>
          <a-input v-model='form.username' />
        </a-form-model-item>
        <a-form-model-item label='绑定手机' prop='mobile'>
          <a-input v-model='form.mobile' />
        </a-form-model-item>
        <a-form-model-item label='用户头像' prop='avatar'>
          <a-upload
            list-type='picture-card'
            class='avatar-uploader'
            :show-upload-list='false'
            :customRequest='handleUpload'>
            <img v-if='form.avatar' :src='form.avatar' alt='avatar' width='375px' />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class='ant-upload-text'>上传</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='用户状态' prop='status'>
          <a-radio-group v-model='form.status'>
            <a-radio :value='0'>注销</a-radio>
            <a-radio :value='1'>有效</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='职位' prop='role'>
          <a-select v-model='form.role'
                    :default-active-first-option='false'
                    :filter-option='false'
                    placeholder="请选择">
            <a-select-option v-for='item in roleList' :key='item.id'>
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <a-form-model-item :wrapper-col=' { lg: { span: 15 ,offset: 7}, sm: { span: 17 ,offset: 7} }'>
        <a-button @click='handleReset'>
          重置
        </a-button>
        <a-divider type='vertical' />
        <a-button @click='handleCancel'>
          取消
        </a-button>
        <a-divider type='vertical' />
        <a-button type='primary' @click='handleSubmit'>
          提交
        </a-button>
      </a-form-model-item>
    </a-card>

  </page-header-wrapper>
</template>

<script>
import md5 from 'md5'
import client from '@/config/oss.config.js'
import { getOperator, putOperator } from '@/api/operator'
import { queryRoles } from '@/api/role'

export default {
  name: 'OperatorEdit',
  data() {
    return {
      roleList: [],
      loading: false,
      subjects: [],
      labelCol: { lg: { span: 7 }, sm: { span: 7 } },
      wrapperCol: { lg: { span: 15 }, sm: { span: 17 } },

      parameter: {},
      form: {
        id: '',
        avatar: '',
        nickname: '',
        mobile: '',
        status: '',
        username: '',
        role: []
      },
      rules: {
        nickname: [
          { required: true, message: '请填写用户名称', trigger: 'blur' },
          { min: 2, max: 16, message: '用户名称字符数限定2～16个', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写登陆账户', trigger: 'blur' },
          { min: 6, max: 11, message: '请填写11位手机号码', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请填写手机号', trigger: 'change' },
          { pattern: /^1[3456789]\d{9}$/, message: '请填写正确的手机号' }
        ],   
        // avatar: [{ required: true, message: '请上传用户头像', trigger: 'change' }],
        status: [
          { required: true, message: '请选择用户状态', trigger: 'change' }
        ],
        role: [
          { required: true, message: '请选择用户角色', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.handleInitial()
  },
  activated() {
    this.handleInitial()
    // debugger;
  },
  methods: {
    async handleUpload(event) {
      let result = await client.put('/operator/avatar/' + event.file.uid, event.file)
      this.form.avatar = result.url
    },
    handleReset(e) {
      this.$refs.form.resetFields()
    },
    handleCancel(e) {
      this.$router.back()
    },
    handleInitial() {
      queryRoles({}).then(result => {
        this.roleList = result
      })
      const { id } = this.$route.query
      if (id) {
        getOperator(this.$route.query).then(result => {
          this.form = Object.assign({}, this.form, result)
        })
      }
    },
    async handleSubmit(e) {
      e.preventDefault()
      this.$refs.form.validate((result) => {
        if (result) {
          putOperator(Object.assign({}, this.form, { password: this.form.password || md5('000000') })).then(_result => {
            this.$router.back()
          })
        }
      })
    }

  }
}
</script>
