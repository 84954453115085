import request from '@/utils/request'

export function queryRoles(parameter) {
  return request({
    url: '/permission/role',
    method: 'post',
    data: parameter
  })
}

